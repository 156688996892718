import React from "react"

import { Layout } from "../components/layout"
import { Team } from "../components/team"
import { teamContent } from "../content/team"

const { pageTitle, ...teamProps } = teamContent

export default function TeamPage() {
  return (
    <Layout pageTitle={pageTitle} color="yellow">
      <Team {...teamProps} />
    </Layout>
  )
}
