// Do not edit this file! It is generated by prebuild script

import { StaticImage } from "gatsby-plugin-image"
import { IStaticImageProps } from "gatsby-plugin-image/dist/src/components/static-image.server"
import React from "react"

import { TeamContent } from "../content-types"

const photoProps: Omit<IStaticImageProps, "src" | "alt"> = {
  placeholder: "none",
  layout: "fluid",
  maxWidth: 1088,
  sizes:
    "(max-width: 400px) 85px, (max-width: 800px) 170px, (max-width: 1200px) 255px, (max-width: 1205px) 256px, (max-width: 1280px) 272px, (max-width: 1600px) 340px, (max-width: 2000px) 425px, (max-width: 2353px) 500px, (max-width: 2400px) 510px, (max-width: 2409px) 512px, (max-width: 2560px) 544px, (max-width: 2800px) 595px, (max-width: 3200px) 680px, (max-width: 3600px) 765px, (max-width: 4000px) 850px, (max-width: 4400px) 935px, (max-width: 4706px) 1000px, (max-width: 4819px) 1024px, (max-width: 5120px) 1088px",
  transformOptions: {},
  blurredOptions: {},
}
export const teamContent: TeamContent = {
  pageTitle: "Our Team",
  title: {
    text: ["Our Team"],
    fontSize: 13,
  },
  teamMembers: [
    {
      photo: (
        <StaticImage
          {...photoProps}
          src="team-member-photos/assen.jpg"
          alt="assen"
          key="assen"
        />
      ),
      show: true,
      name: "Assen Lozanov",
      position: "Manager",
      description:
        "Assen is an exemplary supply-chain Senior Consultant with in-depth knowledge of the main SAP logistics modules (SD&MM&WM) and SAP TM SAP Global Trade Services applications. Active in the SAP implementation world for ${1999,5} years, he has successfully delivered several major full-implementation project cycles in large and mid-size companies and has developed a strong project management, training and coordination expertise. During the last few years, he has been leading SAP implementations in Global Trade processes, providing A-Z solutions for mid and large-size companies.",
    },
    {
      photo: (
        <StaticImage
          {...photoProps}
          src="team-member-photos/yury.jpg"
          alt="yury"
          key="yury"
        />
      ),
      show: true,
      name: "Yury Gruznov",
      position: "Manager",
      description:
        "Yury is a Supply Chain IT Consultant specialising in the Global Trade and Supply Chain processes with a solid technical background. Yury has the relevant knowledge and experience of the SAP Global Trade Services application, as well as a detailed understanding of SAP TM, CRM and SAP ERP – the logistics modules (MM, WM and SD). In parallel, he has acquired a significant and extensive insight of the Logistics business processes, including the Forwarding business environment and Global Trade. Experienced in all project phases, starting from analysing the business requirements and processes, conducting workshops, up to implementing, testing, following up issues, training, and providing the relevant documentation and support.",
    },
    {
      photo: (
        <StaticImage
          {...photoProps}
          src="team-member-photos/kristina.jpg"
          alt="kristina"
          key="kristina"
        />
      ),
      show: true,
      name: "Kristina Dryn",
      position: "Senior Consultant",
      description:
        "Kristina is an experienced supply-chain consultant with sound knowledge of the main SAP Logistics modules (SD & MM & WM) and Global Trade Service SAP application. She has successfully delivered several implementation project cycles in big and mid-size companies in Europe (France, Belgium, Nederlands, Germany). She participated in the entire range of project phases - from requirement gathering, realization, testing, user training, go-live and support. She has strong problem-solving skills, good technical understanding of the system and the ability to quickly adapt to the fast-changing international and corporate environment.",
    },
    {
      photo: (
        <StaticImage
          {...photoProps}
          src="team-member-photos/elenemelika.jpg"
          alt="elenemelika"
          key="elenemelika"
        />
      ),
      show: true,
      name: "Elenemelika Sulaberidze",
      position: "Senior Consultant",
      description:
        "Elenemelika is a project manager and Graphic Designer with in-depth knowledge of  the main design software; she has acquired broad experience in providing customized  graphic solutions to wide range of customers within various industries. She has  successfully participated in several major projects developing a strong project management,  training, and coordination experience.",
    },
    {
      photo: (
        <StaticImage
          {...photoProps}
          src="team-member-photos/ann.jpg"
          alt="ann"
          key="ann"
        />
      ),
      show: true,
      name: "Ann Amiranashvili",
      position: "Senior Consultant",
      description:
        "Ann is a confirmed SAP consultant with strong functional and configuration background  with sound knowledge of the main Logistics modules (MM and SD) specifically specialized  in forms/layout and graphic system solutions She is a confirmed Production  and Graphic Designer experienced in the area of scenery and superficial decoration design  and setup, with broad knowledge in providing customized graphic and conceptual solutions  to wide range of customers within various industries. She has worked  at highly ranked advertising and design agencies for more than thousand big  and small companies, brands and studios. She has successfully participated  in several major projects developing a strong project management, training, and coordination experience.",
    },
    {
      photo: (
        <StaticImage
          {...photoProps}
          src="team-member-photos/alexander.jpg"
          alt="alexander"
          key="alexander"
        />
      ),
      show: true,
      name: "Alexander Koltun, Ph.D.",
      position: "Product Architect & Software Developer",
      description:
        "Alexander is a Product Architect and a Web Application Developer. Has more than ${1999,5} years of experience in design and development of information systems using a wide range of various state of the art technologies.  During last ${2014} years his main specialization has been delivering web solutions using React, NodeJS, Ruby on Rails.",
    },
  ],
}
