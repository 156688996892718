import React, { ReactNode, useMemo } from "react"

import { MultilineText } from "../multiline-text"
import { TeamDataProps } from "./dataProps"
import s from "./team.module.scss"

export interface TeamProps extends TeamDataProps {}

const arrangeCards = (
  titleText: string[],
  titleFontSize: number,
  teamMembers: TeamProps["teamMembers"],
) => {
  const allCards = [
    <div key="title" className={`${s.title} ${s.cell}`}>
      <h1 style={{ fontSize: `${titleFontSize / 2}rem` }}>
        <MultilineText text={titleText} />
      </h1>
    </div>,
  ].concat(
    teamMembers
      .filter(m => m.show)
      .map(m => (
        <div key={m.name} className={`${s.member} ${s.cell}`}>
          {m.photo}
          <h1>{m.name}</h1>
          <h2>{m.position}</h2>
          <p>
            {m.description &&
              m.description.replace(
                /\$\{(\d+)(?:,(\d+))?\}/g,
                (_: unknown, since: string, delta: string) =>
                  (
                    Math.floor(
                      (new Date().getFullYear() - Number(since)) /
                        Number(delta || "1"),
                    ) * Number(delta || "1")
                  ).toString(),
              )}
          </p>
        </div>
      )),
  )
  const cards: ReactNode[][] = []
  for (let i = 0; i < allCards.length; i = i + 3) {
    cards.push(allCards.slice(i, i + 3))
  }
  return cards
}

export const Team = ({ title: { text, fontSize }, teamMembers }: TeamProps) => {
  const cards = useMemo(() => arrangeCards(text, fontSize, teamMembers), [
    fontSize,
    teamMembers,
    text,
  ])
  return (
    <div id={s.team}>
      <div className={s.table}>
        {cards.map((cardRow, iRow) => (
          <div key={iRow} className={s.row}>
            {cardRow}
          </div>
        ))}
      </div>
    </div>
  )
}
